<template>
	<div>
		<!-- BEGIN #header -->
		<div id="header" class="app-header" v-bind:class="{ 'app-header-inverse': appOptions.appHeaderInverse }">
			<!-- BEGIN navbar-header -->
			<div class="navbar-header">
				<button type="button" class="navbar-mobile-toggler" v-on:click="toggleSidebarEndMobile" v-if="appOptions.appSidebarTwo">
					<span class="icon-bar"></span>
					<span class="icon-bar"></span>
					<span class="icon-bar"></span>
				</button>
				<router-link to="/" class="navbar-brand">
					<img class="navbar-logo" :src="LogoImg" />
				</router-link>
				<button type="button" class="navbar-mobile-toggler" v-on:click="toggleTopMenuMobile" v-if="appOptions.appTopMenu && !appOptions.appSidebarNone">
					<span class="fa-stack fa-lg text-inverse">
						<i class="far fa-square fa-stack-2x"></i>
						<i class="fa fa-cog fa-stack-1x"></i>
					</span>
				</button>
				<button type="button" class="navbar-mobile-toggler" v-on:click="toggleTopMenuMobile" v-if="appOptions.appTopMenu && appOptions.appSidebarNone">
					<span class="icon-bar"></span>
					<span class="icon-bar"></span>
					<span class="icon-bar"></span>
				</button>
				<button type="button" class="navbar-mobile-toggler" v-on:click="toggleHeaderMegaMenuMobile" v-if="appOptions.appHeaderMegaMenu">
					<span class="fa-stack fa-lg text-inverse m-t-2">
						<i class="far fa-square fa-stack-2x"></i>
						<i class="fa fa-cog fa-stack-1x"></i>
					</span>
				</button>
				<button type="button" class="navbar-mobile-toggler" v-on:click="toggleSidebarMobile" v-if="!appOptions.appSidebarNone">
					<span class="icon-bar"></span>
					<span class="icon-bar"></span>
					<span class="icon-bar"></span>
				</button>
			</div>
			<!-- END navbar-header -->


			<!-- BEGIN header-nav -->
			<div class="navbar-nav d-none d-md-flex">
				<header-mega-menu v-if="appOptions.appHeaderMegaMenu"></header-mega-menu>
				<!--<div class="navbar-item navbar-form">
					<form action="" method="POST" name="search">
						<div class="form-group">
							<input type="text" class="form-control" placeholder="Enter keyword" />
							<button type="submit" class="btn btn-search"><i class="fa fa-search"></i></button>
						</div>
					</form>
				</div>-->

				<!--<b-nav-item-dropdown class="navbar-item" menu-class="dropdown-menu media-list dropdown-menu-end" toggle-class="navbar-link dropdown-toggle icon" no-caret>
					<template slot="button-content">
						<i class="fa fa-bell"></i>
						<span class="badge">5</span>
					</template>
					<div class="dropdown-header">NOTIFICATIONS (5)</div>
					<a href="javascript:;" class="dropdown-item media">
						<div class="media-left">
							<i class="fa fa-bug media-object bg-gray-500"></i>
						</div>
						<div class="media-body">
							<h6 class="media-heading">Server Error Reports <i class="fa fa-exclamation-circle text-danger"></i></h6>
							<div class="text-muted fs-10px">3 minutes ago</div>
						</div>
					</a>
					<a href="javascript:;" class="dropdown-item media">
						<div class="media-left">
							<img src="/assets/img/user/user-1.jpg" class="media-object" alt="" />
							<i class="fab fa-facebook-messenger text-blue media-object-icon"></i>
						</div>
						<div class="media-body">
							<h6 class="media-heading">John Smith</h6>
							<p>Quisque pulvinar tellus sit amet sem scelerisque tincidunt.</p>
							<div class="text-muted fs-10px">25 minutes ago</div>
						</div>
					</a>
					<a href="javascript:;" class="dropdown-item media">
						<div class="media-left">
							<img src="/assets/img/user/user-2.jpg" class="media-object" alt="" />
							<i class="fab fa-facebook-messenger text-blue media-object-icon"></i>
						</div>
						<div class="media-body">
							<h6 class="media-heading">Olivia</h6>
							<p>Quisque pulvinar tellus sit amet sem scelerisque tincidunt.</p>
							<div class="text-muted fs-10px">35 minutes ago</div>
						</div>
					</a>
					<a href="javascript:;" class="dropdown-item media">
						<div class="media-left">
							<i class="fa fa-plus media-object bg-gray-500"></i>
						</div>
						<div class="media-body">
							<h6 class="media-heading"> New User Registered</h6>
							<div class="text-muted fs-10px">1 hour ago</div>
						</div>
					</a>
					<a href="javascript:;" class="dropdown-item media">
						<div class="media-left">
							<i class="fa fa-envelope media-object bg-gray-500"></i>
							<i class="fab fa-google text-warning media-object-icon fs-14px"></i>
						</div>
						<div class="media-body">
							<h6 class="media-heading"> New Email From John</h6>
							<div class="text-muted fs-10px">2 hour ago</div>
						</div>
					</a>
					<div class="dropdown-footer text-center">
						<a href="javascript:;" class="text-decoration-none">View more</a>
					</div>
				</b-nav-item-dropdown>-->
				<b-nav-item-dropdown class="navbar-item" toggle-class="navbar-link dropdown-toggle" no-caret v-if="appOptions.appHeaderLanguageBar">
					<template slot="button-content">
						<span class="flag-icon flag-icon-us me-1" title="us"></span>
						<span class="name d-none d-sm-inline me-1">EN</span> <b class="caret"></b>
					</template>
					<b-dropdown-item href="javascript:;"><span class="flag-icon flag-icon-us" title="us"></span> English</b-dropdown-item>
					<b-dropdown-item href="javascript:;"><span class="flag-icon flag-icon-cn" title="cn"></span> Chinese</b-dropdown-item>
					<b-dropdown-item href="javascript:;"><span class="flag-icon flag-icon-jp" title="jp"></span> Japanese</b-dropdown-item>
					<b-dropdown-item href="javascript:;"><span class="flag-icon flag-icon-be" title="be"></span> Belgium</b-dropdown-item>
					<b-dropdown-divider class="m-b-0"></b-dropdown-divider>
					<b-dropdown-item href="javascript:;" class="text-center">more options</b-dropdown-item>
				</b-nav-item-dropdown>
				<b-nav-item-dropdown menu-class="dropdown-menu-end" class="navbar-item navbar-user dropdown" toggle-class="navbar-link dropdown-toggle d-flex align-items-center">
					<template slot="button-content">
						<img :src="LogoUser" alt="" />
						<b class="chevron">
							<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M8.514 4.74357C8.46239 4.68943 8.40032 4.64634 8.33157 4.61689C8.26281 4.58745 8.1888 4.57227 8.114 4.57227C8.0392 4.57227 7.96519 4.58745 7.89643 4.61689C7.82768 4.64634 7.76561 4.68943 7.714 4.74357L2 10.4576L2.8 11.2576L8.114 5.94357L13.4287 11.2576L14.2287 10.4576L8.514 4.74357Z" fill="#163300"/>
							</svg>
						</b>
					</template>
					<span class="dropdown-text">Кристина Александровна Иванова</span>
					<a href="javascript:;" class="dropdown-item">
						<span class="img-icon">
							<IconSettings />
						</span>
						Профиль
					</a>
					<a href="javascript:;" class="dropdown-item">
						<span class="img-icon">
							<IconHelp />
						</span>
						Помощь
					</a>
					<div class="dropdown-divider"></div>
					<a href="javascript:;" class="dropdown-item" @click.prevent="logout">
						<span class="img-icon">
							<IconLogout />
						</span>
						Выйти
					</a>
				</b-nav-item-dropdown>
				<div class="navbar-divider d-none d-md-block" v-if="appOptions.appSidebarTwo"></div>
				<div class="navbar-item d-none d-md-block" v-if="appOptions.appSidebarTwo">
					<a href="javascript:;" v-on:click="toggleSidebarEnd" class="navbar-link icon">
						<i class="fa fa-th"></i>
					</a>
				</div>
			</div>
			<!-- end header navigation right -->
		</div>
		<!-- end #header -->
	</div>
</template>

<script>
  import AppOptions from '../../config/options.js'
  import HeaderMegaMenu from './HeaderMegaMenu.vue'
  import LogoImg from '../../../public/assets/img/logo/logo.svg'
  import IconLogout from '../icons/IconLogout'
  import IconHelp from '../icons/IconHelp'
  import LogoUser from '../../../public/assets/img/user/user.svg'
  import IconSettings from '../icons/IconSettings'

  export default {
    name: 'Header',
    components: {
      IconSettings,
      IconHelp,
      IconLogout,
      HeaderMegaMenu,
    },
    data() {
      return {
        LogoUser: LogoUser,
        LogoImg: LogoImg,
        appOptions: AppOptions,
      }
    },
    methods: {
      logout() {
		this.$store.dispatch('auth/logout')
        this.$router.push({ path: '/login' })
	  },
      toggleSidebarMobile() {
        this.appOptions.appSidebarMobileToggled = !this.appOptions.appSidebarMobileToggled
      },
      toggleSidebarEnd() {
        this.appOptions.appSidebarEndToggled = !this.appOptions.appSidebarEndToggled
      },
      toggleSidebarEndMobile() {
        this.appOptions.appSidebarEndMobileToggled = !this.appOptions.appSidebarEndMobileToggled
      },
      toggleTopMenuMobile() {
        this.appOptions.appTopMenuMobileToggled = !this.appOptions.appTopMenuMobileToggled
      },
      toggleHeaderMegaMenuMobile() {
        this.appOptions.appHeaderMegaMenuMobileToggled = !this.appOptions.appHeaderMegaMenuMobileToggled
      },
      checkForm: function (e) {
        e.preventDefault()
        this.$router.push({ path: '/extra/search' })
      },
    },
  }
</script>
