<template>
	<div>
		<!-- BEGIN breadcrumb -->
		<ol class="breadcrumb d-none d-md-flex">
			<li class="breadcrumb-item">
				<router-link to="/">Главная</router-link>
			</li>
			<li class="breadcrumb-item">
				<router-link to="/">Личный кабинет</router-link>
			</li>
			<li class="breadcrumb-item active">Мои данные</li>
		</ol>
		<!-- END breadcrumb -->
		<CFQuestionnaire />
	</div>
</template>

<script>
  import CFQuestionnaire from './CFQuestionnaire'
  export default {
    name: 'Profile',
    components: { CFQuestionnaire },
    data() {
      return {
	  }
    },
	watch: {
	},
    computed: {
    },
    created() {
    },
    methods: {
    },
  }
</script>
