import axios from 'axios'
import appOptions from '../../../config/options'

const sleep = m => new Promise(r => setTimeout(r, m))

export default {
  async fetchDeleteProgram({ rootState, commit }, payload) {
    try {
      if (!rootState.auth.token) return { error: true }
      const res = await axios({
        url: process.env.VUE_APP_API_URL + '/orders/remove_program',
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${rootState.auth.token}`,
        },
        data: payload,
      })
      await res.data ? commit('REMOVE_PROGRAM_ITEM', payload) : commit('toast/NEW', {
        type: 'error',
        message: res.error.message,
      }, { root: true })
      return res.data ? res.data : res.error
    } catch (err) {
      commit('toast/NEW', { type: 'error', message: JSON.stringify(err) }, { root: true })
      return err
    }
  },
  async fetchCreatePayer({ rootState, commit }, params) {
    try {
      if (!rootState.auth.token) return { error: true }
      const res = await axios({
        url: process.env.VUE_APP_API_URL + '/orders/createPayerDetail',
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${rootState.auth.token}`,
        },
        data: params,
      })
      await res.data ? commit('SET_PAYER_TO_ITEM', res.data.data) : commit('toast/NEW', {
        type: 'error',
        message: res.error.message,
      }, { root: true })
      return res.data ? res.data : res.error
    } catch (err) {
      commit('toast/NEW', { type: 'error', message: JSON.stringify(err) }, { root: true })
      return err
    }
  },
  async fetchUpdatePayer({ rootState, commit }, params) {
    try {
      if (!rootState.auth.token) return { error: true }
      const res = await axios({
        url: process.env.VUE_APP_API_URL + '/orders/updatePayerDetail/' + params.order_id,
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${rootState.auth.token}`,
        },
        data: params,
      })
      await res.data ? commit('SET_PAYER_TO_ITEM', res.data.data) : commit('toast/NEW', {
        type: 'error',
        message: res.error.message,
      }, { root: true })
      return res.data ? res.data : res.error
    } catch (err) {
      commit('toast/NEW', { type: 'error', message: JSON.stringify(err) }, { root: true })
      return err
    }
  },
  async fetchOrderItem({ rootState, commit }, id) {
    try {
      if (!rootState.auth.token) return { error: true }
      const res = await axios({
        url: process.env.VUE_APP_API_URL + '/orders/' + id,
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${rootState.auth.token}`,
        },
      })
      await res.data ? commit('SET_ORDER_ITEM', res.data.data) : commit('toast/NEW', {
        type: 'error',
        message: res.error.message,
      }, { root: true })
      return res.data ? res.data : res.error
    } catch (err) {
      commit('toast/NEW', { type: 'error', message: JSON.stringify(err) }, { root: true })
      return err
    }
  },
  async fetchOrderItems({ rootState, commit }, page) {
    try {
      if (!rootState.auth.token) return { error: true }
      const res = await axios({
        url: process.env.VUE_APP_API_URL + '/orders/',
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${rootState.auth.token}`,
        },
        data: {
          page: page || 1,
        },
      })
      await res.data ? commit('SET_ORDERS_ITEMS', { page: page || 1, ...res.data }) : commit('toast/NEW', {
        type: 'error',
        message: res.error.message,
      }, { root: true })
      //console.log('ORDER ITEMS', res)
      return res.data ? res.data : res.error
    } catch (err) {
      commit('toast/NEW', { type: 'error', message: JSON.stringify(err) }, { root: true })
      return err
    }
  },
}
