import axios from 'axios'
import appOptions from '../../../config/options'

export default {
  async fetchListenerItem({ rootState, commit }, id) {
    try {
      const res = await axios({
        url: process.env.VUE_APP_API_URL + '/listener/' + id,
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${rootState.auth.token}`,
        },
      })
      const result = await res.data ? commit('SET_LISTENERS_ITEM', res.data.data) : commit('toast/NEW', {
        type: 'error',
        message: res.error.message,
      }, { root: true })
      //console.log('ORDER ITEM', res)
      return result
    } catch (err) {
      commit('toast/NEW', { type: 'error', message: JSON.stringify(err) }, { root: true })
      return  err
    }
  },
  async fetchListenerItems({ rootState, commit }, params) {
    try {
      const res = await axios({
        url: process.env.VUE_APP_API_URL + '/orders/bids/listListeners/',
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${rootState.auth.token}`,
        },
        data: {
          order_id: params.idOrder
        }
      })
      const result = await res.data ? commit('SET_LISTENERS_ITEMS', {...res.data.data, idOrder: params.idOrder}) : commit('toast/NEW', {
        type: 'error',
        message: res.error.message,
      }, { root: true })
      //console.log('ORDER ITEM', res)
      return result
    } catch (err) {
      commit('toast/NEW', { type: 'error', message: JSON.stringify(err) }, { root: true })
      return  err
    }
  },
  async removeListenerItem({ rootState, commit }, id) {
    try {
      const res = await axios({
        url: process.env.VUE_APP_API_URL + '/listener/delete',
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${rootState.auth.token}`,
        },
        data: {
          id: id
        }
      })
      return await res.data ? commit('REMOVE_LISTENER_ITEM', id) : commit('toast/NEW', {
        type: 'error',
        message: res.error.message,
      }, { root: true })
    } catch (err) {
      commit('toast/NEW', { type: 'error', message: JSON.stringify(err) }, { root: true })
      return  err
    }
  },
}
